header {
    height: 50px;
    width: 100%;
    background-color: #333333;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    padding: 0 25px;
    align-items: center;

    img {
        height: 34px;
    }
}