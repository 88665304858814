#sql-playground {
    display: flex;
    justify-content: flex-start;
    height: calc(100vh - 50px);
    width: 100%;

    @media (max-width: 1030px) {
        display: block;
    }

    .editor {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (max-width: 1030px) {
            display: block;
        }
        
        .input {
            height: 50%;
            flex: 1;

            @media (max-width: 1030px) {
                height: 400px;
            }
            
            textarea {
                display: block;
                height: calc(100% - 50px);
                width: 100%;
                font-size: 20px;
                padding: 10px;
                resize: none;
                border-radius: 0;
                border: none;
                outline: none;
            }

            button {
                display: block;
                height: 50px;
                width: 100%;
                font-size: 20px;
                border-radius: 0;
                border: none;
                background: #D45E00;
                color: #fff;
                cursor: pointer;
                font-weight: bold;
                outline: none;
            }
        }

        .output {
            height: 50%;
            flex: 1;
            padding: 1rem;

            .error {
                padding: 1rem;
                border-radius: 10px;
                border: 1px solid rgb(255, 71, 71);
                background-color: rgb(255, 196, 196);
            }

            .success {
                padding: 1rem;
                border-radius: 10px;
                border: 1px solid rgb(5, 138, 0);
                background-color: rgb(127, 197, 148);
            }

            .results_table {
                width: 100%;
                overflow-x: auto;
                table {
                    border-collapse: collapse;
                    width: 100%;
                    td {
                        border: 1px solid #333;
                        padding: 1rem;
                        font-size: 18px;
                    }
                    thead {
                        font-weight: bold;
                        background: rgb(160, 160, 160);
                    }
                }
            }
        }
    }

    .browser {
        width: 100%;
        flex: 1;
        background-color: #c3c3c3;
        padding: 1rem;

        .links {
            margin-bottom: 1rem;
            a {
                color: black;
                padding: 0.6rem;
            }
        }

        .actions {
            display: flex;
            align-items: center;
            button, .input {
                display: block;
                padding: 1rem;
                border: none;
                background-color: #eee;
                margin: 0;
                outline: none;
                font-size: 18px;
            }
            button {
                flex: 1;
                cursor: pointer;
                border-right: 1px solid #333;
            }
            .input {
                flex: 3;
            }
        }

        ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            li {
                padding: 1rem 1.5rem;
                background-color: rgb(214, 214, 214);
                cursor: pointer;
                &:not(:last-child) {
                    border-right: 1px solid #333;
                }
                &.current {
                    background: #D45E00;
                    color: #fff;
                }
            }
        }

        .results_table {
            width: 100%;
            overflow-x: auto;
            table {
                border-collapse: collapse;
                width: 100%;
                td {
                    border: 1px solid #333;
                    padding: 1rem;
                    font-size: 18px;
                }
                thead {
                    font-weight: bold;
                    background: rgb(160, 160, 160);
                }
            }
        }
    }
}