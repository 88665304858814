* {
    box-sizing: border-box;
}
body, html {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

@import "header";
@import "sql";